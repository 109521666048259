.custom-select-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px 0px 10px 5px; /* Adjust padding to accommodate the icon */
    font-size: 16px;
    border: 2px solid #4CAF50;
    border-radius: 5px;
    background-color: transparent;
    color: #333;
    cursor: pointer;
    outline: none;
    width: 50%;
    float: right;
  }
  .filter_tabs{
    padding: 5px;
    background-color: transparent;
    border: 1px solid #4CAF50;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease 0s;
  }
  .stock_tabs{
    padding: 10px 15px;
    background-color: transparent;
    border: 1px solid #4CAF50;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease 0s;
  }

  .active1 {
    background-color: #4CAF50;
    color: white;
  }

  .custom-select:focus {
    border-color: #4CAF50;
  }
  
  .custom-select option {
    padding: 8px 10px; /* Adjust padding to make the options smaller */
    font-size: 14px; /* Adjust font size of options */
  }
  
  .custom-select-arrow {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #4CAF50;
  }
  